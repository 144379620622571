<template>
	<div>
		<v-row dense>
			<v-col cols="12" sm="6" lg="3">
				<v-card class="mb-4">
					<v-card-title> Dati Personali </v-card-title>
					<v-card-text>
						<div class="mb-3 text--primary">
							<span class="font-weight-bold pr-1">{{ $t("label_Name") }}:</span>{{ userProfile.Name }}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("label_Surname") }}:</span>{{ userProfile.Surname }}
							<br />

							<span class="font-weight-bold pr-1">{{ $t("label_Mobile") }}:</span>{{ userProfile.MobilePhoneNo
								| emptyField }}
							<v-tooltip bottom v-if="userProfile.IsMobilePhoneVerified">
								<template v-slot:activator="{ on }">
									<v-icon color="success" size="20" v-on="on">fas fa-check-circle</v-icon>
								</template>
								<span>{{ $t("verifiedNumber") }}</span> </v-tooltip><br />

							<span class="font-weight-bold pr-1">{{ $t("UseCompanyAlias") }}:</span>
							<v-icon>
								{{
									userProfile.UseCompanyAlias ? "fas fa-check-square" : "far fa-square"
								}}
							</v-icon>

							<br />
							<span v-if="userProfile.UseCompanyAlias">
								<span class="font-weight-bold pr-1">{{ $t("CompanyAlias") }}:</span>{{
									userProfile.CompanyAlias | emptyField }}</span>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn icon @click="ShowEditUserProfileDialog">
							<v-icon>fas fa-edit</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>

				<v-dialog v-model="showEditUserProfileDialog" max-width="500px"
					@keydown.esc="showEditUserProfileDialog = false">
					<!-- :initialValues="userProfile" -->
					<SimpleFormCard ref="formUserProfile" :formContent="formUserProfile" :metadata="metadata"
						:metadataLoaded="metadataLoaded" @submitForm="SaveUserProfile" :showCancelButton="false"
						:locali18n="$i18n">
						<template slot="card-title">
							<div class="
																																								primary
																																								white--text
																																								px-5
																																								py-3
																																								d-flex
																																								align-center
																																								justify-space-between
																																							">
								<span class="headline" style="word-break: normal">{{
									$t("editProfile_Header")
								}}</span>
								<v-btn icon dark @click="showEditUserProfileDialog = false">
									<v-icon>close</v-icon>
								</v-btn>
							</div>
						</template>
					</SimpleFormCard>
				</v-dialog>
			</v-col>
			<v-col cols="12" sm="6" lg="3">
				<v-card class="mb-4">
					<v-card-title> Dati di Accesso        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" icon><v-icon>fas fa-cog</v-icon></v-btn>
            </template>
            <v-list>
              <v-list-item @click="ShowChangeEmailDialog">
                <v-list-item-title>{{ $t("modifyEmail") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="ShowResetPasswordDialog">
                <v-list-item-title>{{ $t("resetPassword") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="SendRecoverPasswordMail">
                <v-list-item-title>{{ $t("sendRecoverPasswordMail") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu></v-card-title>
					<v-card-text v-if="userProfile.AspnetUser">
						<div class="mb-3 text--primary">
							<span class="font-weight-bold pr-1">{{ $t("label_Username") }}:</span>{{
								userProfile.AspnetUser.UserName }} <br />
							<span class="font-weight-bold pr-1">{{ $t("label_Email") }}:</span>{{
								userProfile.AspnetUser.Email }}
						</div>

<!--            <v-btn  small color="primary">{{-->
<!--                $t("modifyEmail")-->
<!--              }}</v-btn>-->
<!--            <v-btn @click="ShowResetPasswordDialog" small color="primary">{{-->
<!--                $t("resetPassword")-->
<!--              }}</v-btn>-->
<!--            <v-btn @click="SendRecoverPasswordMail" small color="primary">{{-->
<!--                $t("sendRecoverPasswordMail")-->
<!--              }}</v-btn>-->
					</v-card-text>

				</v-card>
				<v-dialog v-model="showResetPasswordDialog" max-width="500px"
					@keydown.esc="showResetPasswordDialog = false">
					<!-- :initialValues="userProfile" -->
					<SimpleFormCard ref="formResetPassword" :formContent="formResetPassword" :metadata="metadataReset"
						:metadataLoaded="true" @submitForm="ResetPassword" :showCancelButton="false" :locali18n="$i18n">
						<template slot="card-title">
							<div class="primary
										white--text
										px-5
										py-3
										d-flex
										align-center
										justify-space-between
									">
								<span class="headline" style="word-break: normal">{{
									$t("resetPassword_Header")
								}}</span>
								<v-btn icon dark @click="showResetPasswordDialog = false">
									<v-icon>close</v-icon>
								</v-btn>
							</div>
						</template>
					</SimpleFormCard>
				</v-dialog>

				<v-dialog v-model="showChangeEmailDialog" max-width="500px" @keydown.esc="showChangeEmailDialog = false">
					<SimpleFormCard ref="formChangeEmail" :formContent="formChangeEmail" :metadata="metadataChangeEmail"
						:metadataLoaded="true" @submitForm="UpdateEmail" :showCancelButton="false" :locali18n="$i18n">
						<template slot="card-title">
							<div class="primary
																																			white--text
																																			px-5
																																			py-3
																																			d-flex
																																			align-center
																																			justify-space-between
																																								">
								<span class="headline" style="word-break: normal">{{
									$t("updateEmail_Header")
								}}</span>
								<v-btn icon dark @click="showChangeEmailDialog = false">
									<v-icon>close</v-icon>
								</v-btn>
							</div>
						</template>
					</SimpleFormCard>
				</v-dialog>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";
import Account from "@/services/account";

export default {
	$_veeValidate: { validator: "EditUserProfile" },
	components: { SimpleFormCard },
	mixins: [metadata],
	props: {
		UserId: {
			type: String,
		},
		Roles: {
			type: Array,
		},
	},
	data() {
		return {
			userProfile: {},
			showEditUserProfileDialog: false,
			formUserProfile: new CrudFormData("formUserProfile", [
				"Name",
				"Surname",
				"CompanyAlias",
				{
					FieldName: "UseCompanyAlias",
					type: "v-checkbox",
				},
				"MobilePhoneNo",
				{
					FieldName: "IsMobilePhoneVerified",
					type: "v-checkbox",
				},
				// "PostalCode",
				// {
				// 	FieldName: "IsActive",
				// 	type: "v-checkbox",
				// },
			]),
			showResetPasswordDialog: false,
			formResetPassword: new CrudFormData("formResetPassword", ["Password"]),
			metadataReset: {
				Password: {
					AllowSelect: true,
					AllowPatch: false,
					AllowFilter: true,
					AllowSorting: true,
					MaxLength: 20,
					ClientValidators: "min:8|max:20|required",
					DataType: 1,
					NestedParameters: null,
				},
			},
			showChangeEmailDialog: false,
			formChangeEmail: new CrudFormData("formChangeEmail", ["Email"]),
			metadataChangeEmail: {
				Email: {
					AllowSelect: true,
					AllowPatch: false,
					AllowFilter: true,
					AllowSorting: true,
					MaxLength: null,
					ClientValidators: "email|required",
					DataType: 1,
					NestedParameters: null,
				},
			},
		};
	},
	computed: { ...mapGetters(["isUserInRole"]) },

	watch: {
		showEditUserProfileDialog: function (val) {
			!val && this.ClearForm();
		},
		showResetPasswordDialog: function (val) {
			!val && this.ClearResetForm();
		},

		showChangeEmailDialog: function (val) {
			!val && this.ClearChangeEmailForm();
		},
	},
	created() {
		this.UserProfilesService = new CrudClient("UserManagement/UserProfile");
	},
	async mounted() {
		await this.LoadMetadata(this.UserProfilesService, false);

		await this.LoadUserProfile();
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadUserProfile() {
			this.userProfile = await this.UserProfilesService.GetCustom(
				this.UserId,
				false
			);
		},

		async ShowEditUserProfileDialog() {
			this.showEditUserProfileDialog = true;
			await Vue.nextTick();
			this.$refs.formUserProfile.SetValues(this.userProfile);
		},
		async SaveUserProfile(dto) {
			try {
				this.formUserProfile.FormLoading = true;

				await this.UserProfilesService.Patch(this.UserId, dto, false, false);

				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				this.ClearForm();
				this.showEditUserProfileDialog = false;

				// reload user data
				this.userProfile = await this.UserProfilesService.GetCustom(
					this.UserId,
					false
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotUpdateData") });
			} finally {
				this.formUserProfile.FormLoading = false;
			}
		},
		// TODO: after form reset, load again user data as initialValues
		ClearForm() {
			this.$log.debug("Clear form called");
			this.$refs.formUserProfile.ResetForm();
		},

		ShowResetPasswordDialog() {
			this.showResetPasswordDialog = true;
		},

		async ResetPassword(dto) {
			try {
				this.formResetPassword.FormLoading = true;
				await this.UserProfilesService.PostCustom(
					`${this.UserId}/ResetPassword`,
					dto,
					null,
					false,
					false
				);
				this.showResetPasswordDialog = false;
				this.snackSuccess({ Text: this.$t("resetSuccess") });

				this.ClearResetForm();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("resetError") });
			} finally {
				this.formResetPassword.FormLoading = false;
			}
		},
		ClearResetForm() {
			this.$refs.formResetPassword.ResetForm();
		},

		ShowChangeEmailDialog() {
			this.showChangeEmailDialog = true;
		},

		async UpdateEmail(dto) {
			try {
				this.formChangeEmail.FormLoading = true;
				await this.UserProfilesService.PostCustom(
					`${this.UserId}/UpdateEmail`,
					dto,
					null,
					false,
					false
				);
				this.showChangeEmailDialog = false;
				this.snackSuccess({ Text: this.$t("updateEmailSuccess") });

				this.$emit("dataUpdated");
				await this.LoadUserProfile();
				this.ClearChangeEmailForm();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("updateEmailError") });
			} finally {
				this.formChangeEmail.FormLoading = false;
			}
		},
		ClearChangeEmailForm() {
			this.$log.debug("ClearChangeEmailForm");
			this.$refs.formChangeEmail.ResetForm();
		},

		async SendRecoverPasswordMail() {
			try {
				const dto = {};
				dto.Email = this.userProfile.AspnetUser.Email;
				await Account.tryResetPassword(dto);
				this.snackSuccess({ Text: this.$t("resetSuccess") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("resetError") });
			}
		},
	}
};
</script>
<i18n>
{
    "it": {
		"label_NameSurname": "Nome e Cognome",
		"label_Surname": "Cognome",
		"label_Name": "Nome",
		"label_Password": "Password",
		"label_Mobile": "Cellulare",
		"label_Email": "Email",
		"label_Username": "Nome utente",
		"yourSurname": "Il tuo cognome",
		"yourName": "Il tuo nome",
        "MobilePhone": "Il tuo cellulare",
		"resetPassword": "Reimposta la Password",
        "verifiedNumber": "Numero verificato",
		"VerifyNow": "Verifica Ora",
		"VerifyNow_Tooltip": "Verifica il numero di telefono",
		"CompanyAlias": "Nome Profilo",
		"UseCompanyAlias": "Usa Nome Profilo personalizzato",
		"CompanyAlias": "Nome Profilo",
		"editProfile_Header": "Modifica Profilo",
		"resetSuccess": "Password reimpostata con successo",
		"resetError": "Impossibile reimpostare la password ",
		"resetPassword_Header": "Reimposta la password",
    "sendRecoverPasswordMail":"Invia mail per recuperare la password",

		"formUserProfile":{
			"Name": "Nome",
			"Surname": "Cognome",
			"MobilePhoneNo": "Cellulare",
			"IsMobilePhoneVerified": "Cellulare verificato?",
			"CompanyAlias": "Nome Profilo",
			"UseCompanyAlias": "Usa Nome Profilo personalizzato"
		},
		"formResetPassword":{
			"Password": "Password"
		},
		"formChangeEmail":{
			"Email": "La nuova email"
		},
		"updateEmailSuccess": "Email aggiornata con successo",
		"updateEmailError": "Impossibile cambiare la mail ",
		"updateEmail_Header": "Modifica la email",
		"modifyEmail": "Modifica Email"
	}
}</i18n>
