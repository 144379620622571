<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="500px"
		@keydown.esc="CloseDialog"
		persistent
		no-click-animation
	>
		<SimpleFormCard
			ref="formCreateUser"
			:formContent="formCreateUser"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			@submitForm="CreateUser"
			:showCancelButton="false"
			:locali18n="$i18n"
			:initialValues="defaultValues"
		>
			<template slot="card-title">
				<div
					class="primary white--text px-5 py-3 d-flex align-center justify-space-between"
				>
					<span class="headline" style="word-break: normal">{{
						this.dialogHeader || $t("addUserDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";

export default {
	$_veeValidate: { validator: "CreateUser" },
	components: { SimpleFormCard },
	data() {
		return {
			defaultValues: {},
			metadataLoaded: true,
			metadata: {
				Name: {
					ClientValidators: "required",
					MaxLength: null,
				},
				Surname: {
					ClientValidators: "required",
					MaxLength: null,
				},
				UserName: {
					ClientValidators: "min:8|alpha_num",
				},
				UseCompanyAlias: {
					ClientValidators: null,
					MaxLength: null,
				},
				CompanyAlias: {
					ClientValidators: "required|max:100",
					MaxLength: 100,
				},
				Email: {
					ClientValidators: "required|email",
					MaxLength: null,
				},
				Password: {
					ClientValidators: "required|min:8",
					MaxLength: null,
				},
			},
			formCreateUser: new CrudFormData("formCreateUser", [
				"Name",
				"Surname",
				// {
				// 	type: "v-checkbox",
				// 	FieldName: "UseCompanyAlias",
				// 	listeners: {
				// 		change: this.changeUseCompanyAlias,
				// 	},
				// },

				{
					type: "v-text-field",
					FieldName: "UserName",
					"persistent-hint": true,
					hint:
						"campo facoltativo, se non specificato l'indirizzo di mail diventa nome utente",
				},
				"Email",
				"Password",
			]),
		};
	},
	props: {
		value: { type: Boolean, required: true },
		addToRole: { type: String },
		dialogHeader: { type: String },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
	},
	created() {
		this.ApplicationUsersService = new CrudClient("UserManagement/Users");
		this.UserRolesService = new CrudClient("UserManagement/UserRoles");

		this.NutritionistsService = new CrudClient("Nutritionists");
		this.PatientsService = new CrudClient("Patients");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async CreateUser(userData) {
			this.formCreateUser.FormLoading = true;
			const dto = {
				Name: userData.Name,
				Surname: userData.Surname,
				Username: userData.UserName || userData.Email,
				Email: userData.Email,
				Password: userData.Password,
				UseCompanyAlias: userData.UseCompanyAlias,
				CompanyAlias: userData.UseCompanyAlias ? userData.CompanyAlias : null,
			};

			try {
				const userId = await this.ApplicationUsersService.Post(
					null,
					dto,
					"CreateUser",
					true
				);

				if (this.addToRole) {
					await this.UserRolesService.PostCustom(
						null,
						null,
						`${userId}/RoleName/${this.addToRole}`
					);

					if (this.addToRole === "Nutritionist") {
						// (id, dto, urlPostFix,
						await this.NutritionistsService.Post(userId, { IsActive: false });
					} else if (this.addToRole === "Patient") {
						await this.PatientsService.Post(userId, {});
					}
				}

				this.snackSuccess({ Text: this.$t("createuser_Success") });
				this.$emit("updated", userId);
			} catch (error) {
				this.snackError({ Text: this.$t("createuser_Error") });
			} finally {
				this.formCreateUser.FormLoading = false;
				this.CloseDialog();
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formCreateUser) this.$refs.formCreateUser.ResetForm();
		},

		CloseDialog() {
			this.$emit("input", false);
		},

		changeUseCompanyAlias(val) {
			if (!val) {
				this.formCreateUser.Fields = this.formCreateUser.Fields.filter(
					(f) => f.FieldName !== "CompanyAlias"
				);
			} else {
				this.formCreateUser.Fields.splice(3, 0, {
					type: "v-text-field",
					FieldName: "CompanyAlias",
				});
			}
		},
	},
};
</script>

<i18n>
{
    "it": {
		"addUserDialog_Header": "Crea Nuovo Utente",
		"createuser_Success": "Utente creato!",
		"createuser_Error" : "Utente non creato!",
		"Name": "Nome",
		"Surname": "Cognome",
		"FullName": "Nome Cognome",
		"formCreateUser": {
			"Name": "Nome",
			"Surname": "Cognome",
			"UserName": "Nome Utente",
			"Email": "Email",
			"Password": "Password",
			"UseCompanyAlias": "Usa nome azienda",
			"CompanyAlias": "Nome azienda"
		}
    }
}
</i18n>
