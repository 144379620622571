<template>
	<div>

		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>	</v-breadcrumbs>

		<v-progress-linear :indeterminate="true" v-show="LoadingData"></v-progress-linear>

		<h1 class="text-h4 text-sm-h2" v-if="this.UserDetails.AspnetUser">
			{{ this.UserDetails.AspnetUser.UserName }}
		</h1>
		<v-expansion-panels multiple>
			<v-expansion-panel>
				<v-expansion-panel-header><span class="headline ma-0 pa-0">{{ $t("UserProfile") }}</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<UserProfile :UserId="UserId" :Roles="UserRoles" @dataUpdated="LoadUserDetails(UserId)"></UserProfile>

					<DeleteUserCard :UserId="UserId"> </DeleteUserCard>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel v-if="IsPatient || IsNutritionist">
				<v-expansion-panel-header><span class="headline ma-0 pa-0"> Dati di Fatturazione</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<UserBillingProfiles :UserId="UserId"></UserBillingProfiles>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel v-if="IsPatient">
				<v-expansion-panel-header><span class="headline ma-0 pa-0"> Impostazioni Paziente</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<PatientSettings :UserId="UserId">
					</PatientSettings>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel v-if="IsNutritionist">
				<v-expansion-panel-header><span class="headline ma-0 pa-0"> Impostazioni Nutrizionista</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-btn class="primary" large :to="'/Admin/NutritionistProfile/' + UserId">{{
						$t("common.profile") }}</v-btn>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header>
					<span class="headline ma-0 pa-0">{{
						$t("header_UserRoles")
					}}</span></v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-card-text>
						<v-checkbox v-for="role in Roles" :key="role.Id" :label="role.Name" v-model="role.Selected"
							color="red" hide-details></v-checkbox>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="LoadUserRoles" :loading="LoadingData">{{ $t("common.cancel")
						}}</v-btn>
						<v-btn color="primary" @click="UpdateRoles" :loading="LoadingData">{{
							$t("common.save")
						}}</v-btn>
					</v-card-actions>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import UserBillingProfiles from "@/components/Account/UserBillingProfiles.vue";
import UserProfile from "@/components/Account/UserProfile.vue";
import PatientSettings from "./components/PatientSettings";

import DeleteUserCard from "./DeleteUserCard.vue";

export default {
	metaInfo() {
		return {
			title: this.UserDetails?.AspnetUser?.UserName,
		};
	},
	name: "EditUser",
	components: {
		UserBillingProfiles,
		UserProfile,
		DeleteUserCard,
		PatientSettings
	},
	data() {
		return {
			LoadingData: false,
			Roles: [],
			UserRoles: [],
			UserDetails: [],

			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},
	computed: {
		IsPatient() {
			return this.IsCurrentUserInRole("Patient");
		},
		IsNutritionist() {
			return this.IsCurrentUserInRole("Nutritionist");
		},

		UserId() {
			return this.$route.params.UserId;
		}
	},

	created() {
		// add services to this
		this.RolesService = new CrudClient("UserManagement/Roles");
		this.UserRolesService = new CrudClient("UserManagement/UserRoles");
		this.UserProfilesService = new CrudClient("UserManagement/UserProfile");
	},
	async mounted() {
		this.$log.info("EditUser Mounted");
		this.breadcrumbsItems.push(
			{
				text: this.$t("users"),
				disabled: false,
				exact: true,
				to: "/Users",
			},
			{
				text: this.$t("pageTitle"),
				disabled: false,
				exact: true,
				to: "/Users/UserList",
			}
		);
		await this.LoadAllRoles();
		await this.LoadUserRoles();
		await this.LoadUserDetails(this.UserId, true);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		IsCurrentUserInRole(roleName) {
			return !!this.UserRoles.find((ur) => ur.RoleName === roleName);
		},

		async LoadAllRoles() {
			try {
				this.LoadingData = true;
				const res = await this.RolesService.GetCustom(null, false);
				res.forEach((element) => {
					element.Selected = false;
				});
				this.Roles = res;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadUserRoles() {
			try {
				this.LoadingData = true;
				this.Roles.forEach((element) => {
					element.Selected = false;
				});
				const res = await this.UserRolesService.Get(this.UserId);
				this.UserRoles = res;
				res.forEach((element) => {
					this.Roles.find((r) => r.Id === element.RoleId).Selected = true;
				});
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadUserRoles") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async UpdateRoles() {
			try {
				this.LoadingData = true;
				const selectedRoles = this.Roles.filter((r) => r.Selected).map((r) => r.Id);
				await this.UserRolesService.Post(
					this.UserId,
					selectedRoles,
					"SetRoles"
				);
				this.LoadUserRoles();
				this.snackSuccess({ Text: this.$t("snackSuccess_UserRolesUpdated") });
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotUpdateUserRoles") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadUserDetails(id, isInitial = false) {
			try {
				this.LoadingData = true;
				this.UserDetails = await this.UserProfilesService.GetCustom(id, false);
				const c = {
					text: this.UserDetails.AspnetUser.UserName,
					disabled: true,
					exact: true,
					to: "/Users/EditUser/{id}",
				};

				if (isInitial) {
					this.breadcrumbsItems.push(c);
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadDetails") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>

<i18n>
{
    "it": {
		"pageTitle": "Elenco Utenti",
		"users": "Gestione utenti",
		"pageMetaDescription": "Gestisci gli utenti",
		"snackError_CannotLoadUserRoles": "Cannot load user roles",
		"snackError_CannotUpdateUserRoles": "Cannot update user roles",
		"header_UserRoles": "Ruoli dell'Utente",
		"header_ClientDefaultPrices": "Listino predefinito",
		"header_userTags": "Tag dell'Utente",
		"snackSuccess_UserRolesUpdated":"Ruoli sono stati aggiornati!",
		"UserName": "Nome Utente",
		"UserProfile": "Profilo Utente",
		"Email": "Email",
		"header_userSubAccounts": "Sub Account"
    }
}
</i18n>
