<template>
	<div>
		<v-progress-linear :indeterminate="true" v-show="LoadingBillingProfiles"></v-progress-linear>

		<v-alert type="warning" v-if="BillingProfiles.length === 0">
			{{ $t("alertMissingBillingInfo") }}
		</v-alert>

		<v-row dense>
			<v-col cols="12" sm="6" lg="3" v-for="profile in BillingProfiles" :key="profile.BillingProfileId">
				<v-card class="mb-4" :color="profile.IsActive ? '' : 'grey'">
					<v-card-text>
						<div class="mb-3 text--primary">
							<span class="font-weight-bold pr-1">{{ $t("common.name") }}:</span>{{ profile.RagioneSociale
							}}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("common.fiscalCode") }}:</span>{{
							profile.CodiceFiscale }}<br />
							<div v-if="profile.PartitaIva">
								<span class="font-weight-bold pr-1">{{ $t("common.pIva") }}:</span>{{ profile.PartitaIva
								}}<br />
							</div>
							<span class="font-weight-bold pr-1"> {{ $t("common.address") }}:</span>
							{{ profile.Address }} <br />
							{{ profile.PostalCode }} {{ profile.Comune.Name }} ({{ profile.Comune.ProvinciaAbbreviation
							}})
							<template v-if="IsNutritionist">
								<br />
								<span class="font-weight-bold pr-1"> {{ $t("Iban") }}:</span>
								{{ profile.Iban | emptyField }}
							</template>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-chip color="grey--darken-5" dark v-if="!profile.IsActive">{{
							$t("common.isItemActive.false")
							}}</v-chip>
						<v-btn icon @click="ShowEditBillingProfileDialog(profile.BillingProfileId)">
							<v-icon>fas fa-edit</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-btn block class="primary" @click="ShowNewBillingProfileDialog" v-if="BillingProfiles.length === 0">{{
			$t("common.add")
			}}</v-btn>

		<AddEditBillingProfile v-if="IsPatient" v-model="showAddBillingProfile" :mode="dialogData.Mode" :UserId="UserId"
			:BillingProfileId="dialogData.BillingProfileId" @updated="OnBillingProfileUpdated"></AddEditBillingProfile>
		<AddEditNutritionistBillingProfile v-else-if="IsNutritionist" v-model="showAddBillingProfile"
			:mode="dialogData.Mode" :UserId="UserId" :BillingProfileId="dialogData.BillingProfileId"
			@updated="OnBillingProfileUpdated">

		</AddEditNutritionistBillingProfile>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";

import AddEditBillingProfile from "./AddEditBillingProfile";

import AddEditNutritionistBillingProfile from "./AddEditNutritionistBillingProfile";
export default {
	$_veeValidate: { validator: "EditUserBillingProfile" },
	components: { AddEditBillingProfile, AddEditNutritionistBillingProfile },

	props: {
		UserId: {
			type: String,
		},
	},
	data() {
		return {
			showAddBillingProfile: false,
			BillingProfiles: [],
			LoadingBillingProfiles: false,
			dialogData: {
				Mode: 1,
				BillingProfileId: null,
			},
			UserRoles: null
		};
	},
	computed: {
		...mapGetters([
			"isUserInRole"
		]),
		IsPatient() {
			if (this.UserId) {
				if (!this.UserRoles) return false;
				return !!this.UserRoles.find(r => r.RoleName === "Patient")
			}
			return this.isUserInRole("Patient")
		},
		IsNutritionist() {
			if (this.UserId) {
				if (!this.UserRoles) return false;
				return !!this.UserRoles.find(r => r.RoleName === "Nutritionist")
			}
			return this.isUserInRole("Nutritionist")
		}
	},
	watch: {
		showAddBillingProfile: function (val) {
			if (!val) this.dialogData.BillingProfileId = null;
		},
	},
	created() {
		// add services to this
		if (this.UserId) {
			this.BillingProfileService = new CrudClient("User/BillingProfiles");
			this.UserRolesService = new CrudClient("UserManagement/UserRoles");
		} else {
			this.BillingProfileService = new CrudClient("User/BillingProfilesSelf");
		}
	},
	async mounted() {
		await this.LoadUserBillingProfiles();

		if (this.UserId) {
			this.UserRoles = await this.UserRolesService.Get(this.UserId);
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async LoadUserBillingProfiles() {
			try {
				const userId = this.UserId;
				this.LoadingBillingProfiles = true;
				const res = await this.BillingProfileService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "*, Comune.Name, Comune.ProvinciaAbbreviation",
					urlPostFix: this.UserId ? `User/${userId}` : "",
					orderBy: "IsActive:desc",
				});

				this.BillingProfiles = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingBillingProfiles = false;
			}
		},

		async OnBillingProfileUpdated() {
			this.showAddBillingProfile = false;
			await this.LoadUserBillingProfiles();
		},

		ShowNewBillingProfileDialog() {
			this.$log.debug("ShowNewBillingProfileDialog");
			this.showAddBillingProfile = true;
			this.dialogData.Mode = 1;
			this.dialogData.BillingProfileId = null;
		},
		async ShowEditBillingProfileDialog(id) {
			this.showAddBillingProfile = true;
			this.dialogData.Mode = 2;
			this.dialogData.BillingProfileId = id;
		},
	},
};
</script>
<i18n>
{
	"it": {
		"addBillingProfile_Header": "Dati di Fatturazione",
		"NotActive": "Non attivo",
		"formBillingProfile":{
			"RagioneSociale": "Ragione Sociale",
			"CodiceFiscale": "Codice Fiscale",
			"PartitaIva": "Partita IVA",
			"Address": "Indirizzo",
			"ComuneId": "Città",
			"IsActive": "Attivo",
			"PostalCode": "CAP"
		},
		"alertMissingBillingInfo": "Dati di fatturazione mancanti"
	}
}
</i18n>
