<template>
	<v-card>
		<confirm ref="confirm"></confirm>
		<v-card-title>Cancella l'utente</v-card-title>
		<v-card-text>
			<div class="mb-5">
				<span v-if="accountCanBeDeleted"> Puoi cancellare questo account </span>
				<span v-else
					>Non si puo' cancellare questo account. Ci sono dati collegati (i.e.
					ordini, proposte)</span
				>
			</div>

			<v-btn
				color="warning"
				:disabled="!accountCanBeDeleted"
				:loading="deletingAccount"
				@click="deleteUserAccount"
				>Cancella l'utente</v-btn
			>
		</v-card-text>
	</v-card>
</template>
<script>
import Confirm from "@/components/Shared/Common/Confirm";
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	components: { Confirm },

	props: {
		UserId: {
			type: String,
		},
	},
	data() {
		return {
			accountCanBeDeleted: false,
			checkingAccountStatus: false,
			deletingAccount: false,
		};
	},
	created() {
		this.ApplicationUsersExtService = new CrudClient(
			"UserManagementExtensions/Users"
		);
	},
	async mounted() {
		try {
			this.accountCanBeDeleted = await this.ApplicationUsersExtService.Get(
				this.UserId,
				"CanBeDeleted"
			);
		} catch (error) {
			this.snackError("Check CanBeDeleted error");
			this.$captureError(error);
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async deleteUserAccount() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("Stai per cancellare l'account"),
					this.$t("common.confirmDelete"),
					{ color: "error", width: 350 }
				))
			) {
				return;
			}

			try {
				this.deletingAccount = true;
				await this.ApplicationUsersExtService.Delete(this.UserId);
				this.snackSuccess({ Text: "Account Cancellato" });
				this.$router.push("/Users/UserList");
			} catch (error) {
				this.snackError({ Text: "Impossibile cancellare l'account" });
				this.$captureError(error);
			} finally {
				this.deletingAccount = false;
			}
		},
	},
};
</script>
