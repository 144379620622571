<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>	</v-breadcrumbs>

		<div class="elevation-5">
			<v-toolbar flat color="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("pageTitle") }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-menu bottom left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon>fas fa-user-plus</v-icon>
							</v-btn>
						</template>

						<v-list>
							<v-list-item @click="OpenNewUserDialog('Nutritionist')">
								<v-list-item-title>{{ $t("nutriyou.nutritionist") }}</v-list-item-title>
							</v-list-item>
							<v-list-item @click="OpenNewUserDialog('Patient')">
								<v-list-item-title>{{ $t("nutriyou.patient") }}</v-list-item-title>
							</v-list-item>
							<!-- <v-list-item @click="OpenNewSubAccountDialog">
								<v-list-item-title>Sub-Account</v-list-item-title>
							</v-list-item> -->
							<v-list-item @click="OpenNewUserDialog(null)">
								<v-list-item-title>Generico</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-toolbar-items>
			</v-toolbar>

			<v-row class="mx-1" v-if="Roles.length > 0">
				<v-col cols="6" md="2">
					<v-select :items="Roles" item-text="Name" item-value="Id" v-model="FilterByRoleId"
						@change="OnSelectedRoleChanged" :label="$t('filterByRole')"></v-select>
				</v-col>
				<v-col cols="6" md="2">
					<PatientPicker :IsAdmin="true" label="Vai all'account"
						v-if="FilterByRoleId === Roles.find((r) => r.Name === 'Patient').Id"
						@change="$router.push('/Users/EditUser/' + $event)">
					</PatientPicker>
					<UserPicker v-else label="Vai all'account" @change="$router.push('/Users/EditUser/' + $event)">
					</UserPicker>
				</v-col>

			</v-row>

			<skeleton-table v-if="LoadingData" :columns="4" :actions="1"></skeleton-table>

			<v-data-table mobile-breakpoint="1" v-show="!LoadingData" :headers="headers" :items="items"
				:must-sort="true" :server-items-length="itemsPaging.TotalItems" :options.sync="options"
				@update:options="LoadUserList" :footer-props="{
					itemsPerPageOptions: [10, 20, 50, 100],
				}">
				<template v-slot:body="{ items }">
					<tbody>
						<tr v-for="item in items" :key="item.name">
							<template>
								<td>
									{{ item.User.UserProfile.DisplayName }}
								</td>
								<td>
									<v-chip small v-for="roles in item.ApplicationUserRoles"
										v-bind:key="roles.Role.Id">{{ roles.Role.Name }}</v-chip>
								</td>
								<td class="layout">
									<v-btn icon ripple :to="'/Users/EditUser/' + item.UserId">
										<v-icon color="primary">fas fa-fw fa-user-edit</v-icon>
									</v-btn>
								</td>
							</template>
						</tr>
					</tbody>
				</template>
			</v-data-table>
		</div>

		<CreateUserDialog v-model="showAddUserDialog" @updated="NavigateToUserDetails" :addToRole="addNewUserToRole"
			:dialogHeader="newUserDialogHeader"></CreateUserDialog>
		<CreatePatientDialog v-model="showAddPatientDialog" @updated="NavigateToUserDetails"
			:addToRole="addNewUserToRole">
		</CreatePatientDialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CrudClient from "@/services/CrudClient/";
import CreateUserDialog from "@/components/Account/CreateUserDialog";
import CreatePatientDialog from "@/components/Account/CreatePatientDialog";
import UserPicker from "./UserPicker";
import PatientPicker from "../Nutritionists/components/PatientPicker.vue";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "UserList",
	$_veeValidate: { validator: "UserList" },
	components: {
		CreateUserDialog,
		CreatePatientDialog,
		UserPicker,
		PatientPicker
	},

	data() {
		return {
			LoadingData: false,
			items: [],
			itemsPaging: {},
			Roles: [],
			FilterByRoleId: null,
			FilterByClientUserId: null,
			options: {
				page: 1,
				itemsPerPage: 10,
				totalItems: 0,
				sortBy: ["User.UserName"],
				sortDesc: [false],
			},
			headers: [
				{
					text: this.$t("FullName"),
					value: "User.UserProfile.DisplayName",
					sortable: true,
				},
				{ text: this.$t("roles"), sortable: false },
				{ text: this.$t("common.actions"), sortable: false },
			],

			showAddUserDialog: false,
			showAddPatientDialog: false,
			newUserDialogHeader: null,
			addNewUserToRole: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},
	computed: {
		...mapGetters(["UserPublicProfiles"]),
	},

	created() {
		this.RolesService = new CrudClient("UserManagement/Roles");
		this.ApplicationUsersService = new CrudClient("UserManagement/Users");
	},
	async mounted() {
		this.$log.info("UserList View Mounted");
		this.breadcrumbsItems.push(
			{
				text: this.$t("users"),
				disabled: false,
				exact: true,
				to: "/Users",
			},
			{
				text: this.$t("pageTitle"),
				disabled: true,
				exact: true,
				to: "/Users/UserList",
			}
		);

		this.LoadAllRoles();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async OnSelectedRoleChanged() {
			this.FilterByClientUserId = null;
			await this.LoadUserList();
		},

		async LoadUserList() {
			const filter = this.FilterByRoleId
				? "ApplicationUserRoles.RoleId:" + this.FilterByRoleId
				: "";

			try {
				this.LoadingData = true;
				const res = await this.ApplicationUsersService.GetPaged({
					limit: this.options.itemsPerPage,
					skip: (this.options.page - 1) * this.options.itemsPerPage,
					fields: `*,
						User.UserProfile.DisplayName,
						ApplicationUserRoles.*,
						ApplicationUserRoles.Role.*`,
					filter: filter,
					orderBy:
						this.options.sortBy[0] + (this.options.sortDesc[0] ? ":desc" : ""),
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		OpenNewUserDialog(role) {
			this.addNewUserToRole = role;

			switch (role) {
				case "Nutritionist":
					this.newUserDialogHeader = "Nuovo Nutrizionista";
					this.showAddUserDialog = true;
					break;
				case "Patient":
					this.showAddPatientDialog = true;
					break;
				default:
					this.showAddUserDialog = true;
					this.newUserDialogHeader = null;
			}
		},

		async LoadAllRoles() {
			try {
				this.LoadingData = true;
				this.Roles = await this.RolesService.GetCustom(null, false);
				this.Roles.unshift({ Id: null, Name: "-" });

				this.FilterByRoleId = this.Roles.find((r) => r.Name === "Patient").Id;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		NavigateToUserDetails(userId) {
			this.$router.push("/Users/EditUser/" + userId);
		},
	},
};
</script>

<i18n>
{
    "it": {
		"pageTitle": "Elenco Utenti",
		"users": "Gestione utenti",
		"pageMetaDescription": "Tutti gli utenti",
		"UserName": "Nome Utente",
		"Email": "Email",
		"Password": "Password",
		"addUserDialog_Header": "Crea Nuovo Utente",
		"createuser_Success": "Utente creato!",
		"createuser_Error" : "Utente non creato!",
		"Name": "Nome",
		"Surname": "Cognome",
		"FullName": "Nome Cognome",
		"adduser_Success":"Utente aggiunto",
		"adduser_NotFound": "Utente non trovato",
		"formCreateUser": {
			"Name": "Nome",
			"Surname": "Cognome",
			"UserName": "Nome Utente",
			"Email": "Email",
			"Password": "Password"
		},
		"roles": "Ruoli",
		"filterByRole": "Filtra per ruolo",
		"filterByOwner": "Filtra per cliente"
    }
}
</i18n>
