<template>
	<div>

		<ConfirmTool ref="confirm"></ConfirmTool>

		<div v-if="patientData">
			<ClientAccountPicker class="mr-3" style="max-width: 240px" RoleName="Nutritionist"
				:label="$t('nutriyou.nutritionist')" v-model="nutritionistUserId" v-if="
					!patientData.DefaultNutritionistUserId
				"></ClientAccountPicker>
			<v-chip dark color="secondary" v-if="
				patientData.DefaultNutritionistUserId
			">
				Assegnata a
				<span class="mx-2 font-weight-bold">{{
					patientData.DefaultNutritionist.DisplayName
				}}</span>
			</v-chip>

			<v-btn color="primary" v-if="
				!patientData.DefaultNutritionistUserId
			" @click="assignNutritionist"
				:loading="SendingRequest">Assigna</v-btn>

			<v-btn color="error" class="ml-3 text-capitalize" small v-else
					@click="unassignNutritionist"
				:loading="SendingRequest"><v-icon left small>fas fa-unlink</v-icon> Scollega dal nutrizionista</v-btn>
		</div>
	</div>
</template>
<script>
import ClientAccountPicker from "@/components/Account/ClientAccountPicker";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	components: {
		ClientAccountPicker,
		ConfirmTool
	},
	props: {
		UserId: {
			type: String,
			required: true
		},
	},

	data() {
		return {
			patientData: null,
			SendingRequest: false,
			nutritionistUserId: null,
		}
	},

	created() {
		this.PatientsService = new CrudClient("Patients");
		this.NutritionistsService = new CrudClient("Nutritionists");
	},
	async mounted() {
		await this.LoadPatientData();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadPatientData() {
			this.patientData = await this.PatientsService.Get(this.UserId);

			this.nutritionistUserId = this.patientData.DefaultNutritionistUserId;
		},

		async unassignNutritionist() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("Scollega dal nutrizionista"),
					`Stai per scollegare  [${this.patientData.DefaultNutritionist.DisplayName}] dall'utente [${this.patientData.ApplicationUser.DisplayName}]. 
					Lo storico della chat verra cancellata in un modo irreversibile!`,
					{ color: "error" }
				))
			) {
				return;
			}

			try {
				this.SendingRequest = true;

				await this.PatientsService.Post(
					this.UserId,
					null,
					"UnassignNutritionist"
				);
				this.snackSuccess({ Text: "Nutrizionista scollegato!" });

				await this.LoadPatientData();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile scollegare" });
			} finally {
				this.SendingRequest = false;
			}
		},

		async assignNutritionist() {
			if (!this.nutritionistUserId) {
				this.snackError({ Text: "Seleziona Nutrizionista!" });

				return;
			}

			try {
				this.SendingRequest = true;

				const selectedNutritionist = await this.NutritionistsService.Get(
					this.nutritionistUserId
				);

				if (
					!(await this.$refs.confirm.open(
						this.$t("Assegna Nutrizionista"),
						`Stai per assegnare  [${selectedNutritionist.ApplicationUser.DisplayName}] all'utente [${this.patientData.ApplicationUser.DisplayName}]`,
						{ color: "warning" }
					))
				) {
					return;
				}

				await this.PatientsService.Post(
					this.UserId,
					{
						NutritionistUserId: this.nutritionistUserId,
					},
					"AssignNutritionist"
				);
				this.snackSuccess({ Text: "Nutrizionista assegnato!" });

				await this.LoadPatientData();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile creare l'utente" });
			} finally {
				this.SendingRequest = false;
			}
		},
	},
}

</script>
