<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>

		<v-card class="elevate-5">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("pageTitle") }}</v-toolbar-title>
			</v-toolbar>

			<v-row class="mx-1 py-3">
				<v-col
					cols="12"
					sm="6"
					md="4"
					class="py-1 static-navigation"
					v-for="item in staticNavigation"
					:key="item.text"
				>
					<v-card
						dark
						color="secondary"
						class="pa-1 elevateOnHover"
						:to="{ path: item.path }"
						append
					>
						<v-card-text>
							<v-icon class="mr-2" v-if="item.icon">{{ item.icon }}</v-icon>
							<span class="ItemText white--text">{{ item.text }}</span>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		return {
			LoadingData: false,
			staticNavigation: [
				{
					path: "UserList",
					text: this.$t("linkUserList"),
					icon: "fas fa-users",
				},
				// {
				// 	path: "Tags",
				// 	text: this.$t("sgp.tag"),
				// 	icon: "fas fa-tag",
				// },
			],
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},
	mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Users",
		});
	},
};
</script>

<i18n>
{
    "it": {
		"pageTitle": "Gestione Utenti",
		"pageMetaDescription": "Gestisci gli utenti",
		"linkUserList": "Elenco utenti"
    },
    "en": {
		"pageTitle": "User Management",
		"pageMetaDescription": "Manage your users",
		"linkUserList": "All users"
    }
}
</i18n>
