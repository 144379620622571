var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ConfirmTool',{ref:"confirm"}),(_vm.patientData)?_c('div',[(
				!_vm.patientData.DefaultNutritionistUserId
			)?_c('ClientAccountPicker',{staticClass:"mr-3",staticStyle:{"max-width":"240px"},attrs:{"RoleName":"Nutritionist","label":_vm.$t('nutriyou.nutritionist')},model:{value:(_vm.nutritionistUserId),callback:function ($$v) {_vm.nutritionistUserId=$$v},expression:"nutritionistUserId"}}):_vm._e(),(
			_vm.patientData.DefaultNutritionistUserId
		)?_c('v-chip',{attrs:{"dark":"","color":"secondary"}},[_vm._v(" Assegnata a "),_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.patientData.DefaultNutritionist.DisplayName))])]):_vm._e(),(
			!_vm.patientData.DefaultNutritionistUserId
		)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.SendingRequest},on:{"click":_vm.assignNutritionist}},[_vm._v("Assigna")]):_c('v-btn',{staticClass:"ml-3 text-capitalize",attrs:{"color":"error","small":"","loading":_vm.SendingRequest},on:{"click":_vm.unassignNutritionist}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fas fa-unlink")]),_vm._v(" Scollega dal nutrizionista")],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }